import _1 from "./1.jpg";
import _2 from "./2.png";
import _3 from "./3.png";
import _4 from "./4.png";
import _5 from "./5.png";
import _6 from "./6.png";

const data =[_1,_2,_3,_4,_5 , _6];



export default data;
