import RoutesComp from './Routes'; 
import './App.css';

function App() {
  
 

  return (
  <RoutesComp></RoutesComp>
  );
}

export default App;
